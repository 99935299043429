.heroHeader {
    background-size: cover;
    background-position: center;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.heroHeader .gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(180deg,#171a1c,rgba(23,26,28,0));
}

.heroHeader .heroImage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -2;
}

.title {
    font-size: 5rem;
    color: white;
    text-shadow: 1px 1px 4px #000000;
}

.tagline {
    font-size: 2rem;
    color: white;
    text-shadow: 1px 1px 4px #000000;
    margin-top: 1rem;
}

.navButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    position: absolute;
    width: 100%;
}

.prevButton, .nextButton {
    padding: 1rem 2rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.prevButton:hover, .nextButton:hover {
    background-color: #3e8e41;
}

.progressBarContainer {
    width: 100%;
    height: 5px;
    background-color: #ccc;
    position: relative;
    margin-top: -5px;
    overflow-y: hidden;

}

.progressBar {
    height: 100%;
    background-color: #0077C8;
    position: absolute;
    top: 0;
}