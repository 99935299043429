.navbar {
    width: 100%;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    z-index: 1000;
}

.greenNavbar ul li a.active {
    color: #3e8e41!important;
}

.greenNavbar ul li a:hover, .greenNavbar a:hover {
    color: #3e8e41!important;
}

.blueNavbar ul li a.active {
    color: #0072C6!important;
}

.blueNavbar ul li a:hover, .greenNavbar a:hover {
    color: #0072C6!important;
}


.navbarHidden {
    transform: translateY(-100%);
}

.customnavbarHidden {
    transform: translateY(-35px)!important;
}

.customnavbarHiddenWithOutTransition {
    transform: translateY(-35px)!important;
    transition: none!important;
}


.navbarVisible {
    transform: translateY(0);
}

.topnavbar {
    width: 100%;
    height: 35px;
    position: fixed;
    background: #040408;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    z-index: 1000;
    transition: transform 0.3s ease-out;
}

.customnavbar {
    width: 100%;
    height: 50px;
    position: fixed;
    background: #07111c;
    top: 35px;
    left: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    z-index: 1000;
    transition: transform 0.3s ease-out;
}

.navbar .brandmark, .topnavbar .brandmark, .customnavbar .brandmark {
    display: block;
    fill: #ffffff;
    display: flex;
    align-items: center;
}

.customnavbar .brandmark {
    padding: 0;
}

.customnavbar .brandmark img {
    height: 40px;
}

.customnavbar .brandmarkWidth img {
    width: 150px!important;
    height: auto!important;
}

.navbar ul, .topnavbar ul, .customnavbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.topnavbar a {
    font-size: 13px!important;
}

.navbar a, .customnavbar a {
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat";
    font-weight: bold;
    position: relative;
    display: block;
    color: #fff;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 1.5;
    text-decoration: none;
    transition: all 0.3s;
}

.topnavbar a {
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat";
    font-weight: bold;
    position: relative;
    display: block;
    color: #fff;
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 1.5;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar a:hover, .topnavbar a:hover {
    text-decoration: none;
    color: #0072C6;
}

.customnavbar a:hover {
    text-decoration: none;
    color: #FDB813;
}

.flexcenter {
    display: flex;
    justify-content: center;
}

.active {
    color: #0072C6!important;
}

.customnavbar .active {
    color: #FDB813!important;
}

.button {
    display: inline-block;
    padding: 10px 40px!important;
    border: 0;
    text-decoration: none;
    border-radius: 100px;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(30px);
    color: rgba(255,255,255,0.8)!important;
    font-size: 12px;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
    line-height: normal!important;
    display: initial!important;
}

.button:hover {
    background-color: rgba(255,255,255,0.2);
    border: 1px solid rgba(255,255,255,0.2);
    color: #ffffff!important;
}

.buttonYellow {
    padding: 10px 15px!important;
    border-radius: 100px;
    background-color: #FDB813!important;
    border: 1px solid #FDB813!important;
    color: #000!important;
    line-height: initial!important;
    display: initial!important;
}

.buttonYellow:hover {
    background-color: rgba(253, 184, 19, 0.8) !important;
    border: 1px solid rgba(253, 184, 19, 0.8) !important;
    color: rgba(0, 0, 0, 0.8) !important;
}