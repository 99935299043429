.container {
    padding: 40px;
    text-align: center;
}

.container {
    padding-left: 15px;
    padding-right: 15px;
}
.container {
    width: 100%;
    margin: auto;
}
.container {
    max-width: 1140px;
}

.title {
    font-size: 36px;
    margin-bottom: 40px;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.listItem {
    width: calc(50% - 30px);
    margin-left: 30px;
    margin-bottom: 30px;
    text-align: left;
    border-radius: 20px;
    background: #1E2835;
    overflow: hidden;
}

.listItemContent {
    background: #1E2835;
    overflow: hidden;
    border-radius: 0 0 20px 20px;
    padding: 20px 18px;
    position: relative;
    border: 2px solid rgba(255, 255, 255, 0.0784313725);
    border-top: 0;
}

.listItemTitle {
    font-size: 28px;
    color: #fff;
    text-decoration: none;
    font-family: "Montserrat";
    font-weight: bold;
    transition: all 0.3s;
}

.listItemTitle:hover {
    color: #FDB813;
    text-decoration: none;
}

.listItemDescription {
    margin-bottom: 20px;
}

.listItemLink {
    padding: 10px 15px;
    border-radius: 100px;
    background-color: #FDB813;
    border: 1px solid #FDB813;
    font-weight: bold;
    color: #000;
    line-height: initial;
    display: initial;
    text-decoration: none;
    transition: all 0.3s;
}

.listItemLink:hover {
    background-color: rgba(253, 184, 19, 0.8);
    border: 1px solid rgba(253, 184, 19, 0.8);
    color: rgba(0, 0, 0, 0.8) ;
    cursor: pointer;
    text-decoration: none;
}

.listItemText {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 10;
    margin-bottom: 24px;
    padding-bottom: 20px;
    flex-direction: column;
}

.listItemText::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    background: #798DA3;
    opacity: 0.12;
    bottom: 0;
    left: 0;
}

.listItemCover {
    width: 100%;
    height: 200px;
    background-color: #333;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    position: relative;
}

.listItemTagsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

.listItemTag {
    font-size: 12px;
    line-height: 1.4;
    background-color: #798DA3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 77px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    margin-top: 10px;
    font-weight: bold;
}

.listItemTag:hover {
    background-color: #fff;
    color: #333;
    cursor: pointer;
}