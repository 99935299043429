.text {
    font-family: 'Montserrat', sans-serif;
    font-width: 900;
    font-size: 3rem;
    color: #ffffff;
    line-height: 1.5;
    width: 100%;
    text-transform: uppercase;
    text-align: start;
}

.smalltext {
    font-family: 'Montserrat', sans-serif;
    font-width: 900;
    font-size: 1rem;
    color: #ffffff;
    line-height: normal;
    width: 100%;
    text-align: start;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    color: #fff;
    min-height: 90vh;
    width: 50%;
    padding-left: 64px;
    padding-right: 64px;
}

.textContainerCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    min-height: 90vh;
    width: 100%;
    padding-left: 64px;
    padding-right: 64px;
}

.textContainerText {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.center {
    text-align: center!important;
}

.heroHeader {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
}

.heroHeader .gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: linear-gradient(180deg,#171a1c,rgba(23,26,28,0));
}

.heroHeader .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -2;
    background: url('https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072823_1280.jpg') center center no-repeat;
    background-size: cover;
}

.overflowHidden {
    overflow: hidden;
    height: 100vh;
    width: 100%;
}